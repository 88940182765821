const general = {
    placeholder: 'Miejsce na tekst',
    submit: 'Wyślij',
    skip: 'Pomiń',
    next: 'Dalej',
    back: 'Wstecz',
    retry: 'Spróbuj ponownie',
    done: 'Gotowe',
    search: 'Szukaj',
    search_placeholder: 'Szukaj',
    clear_result: 'Wyczyść Wyniki',
    save: 'Zapisz',
    save_changes: 'Zapisz zmiany',
    saved: 'Zapisane',
    discard: 'Porzuć',
    loading: 'Trwa ładowanie...',
    redirecting: 'Przekierowywanie...',
    add: 'Dodaj',
    added: 'Dodano',
    cancel: 'Anuluj',
    confirm: 'Potwierdź',
    check_out: 'Zapłać i wyjdź',
    create: 'Stwórz',
    set_up: 'Skonfiguruj',
    customize: 'Dostosuj',
    enable: 'Włącz',
    reminder: 'Przypomnienie',
    edit: 'Edytuj',
    delete: 'Usuń',
    deleted: 'Usunięto',
    more_options: 'WIĘCEJ OPCJI',
    close: 'Zamknij',
    copy: 'Kopiuj',
    copying: 'Kopiowanie',
    copied: 'Skopiowano',
    required: 'Wymagane',
    add_another: 'Dodaj kolejny',
    create_another: 'Stwórz kolejny',
    deletion_confirmation: 'Czy na pewno chcesz usunąć {{title}}?',
    settings_nav: 'Ustawienia',
    unsaved_changes_warning: 'Wprowadzono zmiany. Czy na pewno chcesz opuścić tę stronę?',
    leave_page: 'Opuść stronę',
    stay_on_page: 'Pozostań na stronie',
    type_to_search: 'Szukaj',
    got_it: 'Zrozumiałe(a)m',
    continue: 'Kontynuuj',
    page_info: '{{min, number}}-{{max, number}} z {{total, number}}',
    learn_more: 'Dowiedz się więcej',
    /** UNTRANSLATED */
    tab_error_one: '{{count, number}} error',
    tab_error_other: '{{count, number}} błędów',
    skip_for_now: 'Pomiń na teraz',
    remove: 'Usuń',
    visit: 'Odwiedź',
    join: 'Dołącz',
    try_now: 'Wypróbuj teraz',
    multiple_form_field: '(Wiele)',
    demo: 'Demo',
    unnamed: 'Bez nazwy',
    view: 'Pokaż',
    open: 'Otwórz',
    hide: 'Ukryj',
    unknown_error: 'Nieznany błąd, spróbuj ponownie później.',
    select: 'Wybierz',
    contact_us_action: 'Skontaktuj się z nami',
    description: 'Opis',
    name: 'Nazwa',
    add_field: 'Dodaj {{field}}',
    create_field: 'Utwórz {{field}}',
    edit_field: 'Edytuj {{field}}',
    delete_field: 'Usuń {{field}}',
    coming_soon: 'Wkrótce dostępne',
    or: 'Lub',
};
export default Object.freeze(general);
