const invitation = {
    find_your_tenants: 'Znajdź swoich najemców',
    find_tenants_description: 'Twój adres e-mailowy może być już zarejestrowany u wielu najemców. Możesz wybrać dołączenie do istniejących lub kontynuować tworzenie nowego.',
    create_new_tenant: 'Utwórz nowego najemcę',
    email_not_match_title: 'Jesteś obecnie zalogowany jako\n{{email}}',
    email_not_match_description: 'Zaloguj się na poprawne konto, aby zaakceptować zaproszenie i stać się członkiem organizacji.',
    switch_account: 'Zaloguj się na inne konto',
    invalid_invitation_status: 'Nieprawidłowe zaproszenie. Skontaktuj się z administratorem i spróbuj ponownie.',
    invitation_not_found: 'Zaproszenie nie znalezione. Skontaktuj się z administratorem.',
};
export default Object.freeze(invitation);
