const users = {
    page_title: 'Zarządzanie użytkownikami',
    title: 'Zarządzanie użytkownikami',
    subtitle: 'Zarządzaj tożsamościami użytkowników, w tym tworzeniem użytkowników, edycją informacji o użytkownikach, przeglądaniem dzienników użytkowników, resetowaniem hasła i usuwaniem użytkowników.',
    create: 'Dodaj użytkownika',
    create_subtitle: 'Podaj co najmniej jedno z poniższych pól, aby kontynuować.',
    error_missing_identifier: 'Aby utworzyć użytkownika, musisz podać co najmniej jeden identyfikator.',
    user_name: 'Użytkownik',
    application_name: 'Z aplikacji',
    latest_sign_in: 'Najnowsze logowanie',
    create_form_username: 'Nazwa użytkownika',
    create_form_password: 'Hasło',
    create_form_name: 'Imię i nazwisko',
    placeholder_name: 'Jan Kowalski',
    placeholder_email: 'jkowalski@example.com',
    placeholder_username: 'user123',
    placeholder_phone: '+48 123 456 789',
    unnamed: 'Bez nazwy',
    search: 'Wyszukaj według nazwy, e-maila, numeru telefonu lub nazwy użytkownika',
    check_user_detail: 'Sprawdź szczegóły użytkownika',
    placeholder_title: 'Zarządzanie użytkownikami',
    placeholder_description: 'Każdy użytkownik ma profil zawierający wszystkie informacje o użytkowniku. Składa się z podstawowych danych, tożsamości społecznych i niestandardowych danych.',
};
export default Object.freeze(users);
