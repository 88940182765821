const verification_code = {
    phone_email_empty: 'Oba pola telefonu i emaila są puste.',
    not_found: 'Nie znaleziono kodu weryfikacyjnego. Proszę najpierw wysłać kod weryfikacyjny.',
    phone_mismatch: 'Niepoprawny numer telefonu. Proszę poprosić o nowy kod weryfikacyjny.',
    email_mismatch: 'Niepoprawny adres email. Proszę poprosić o nowy kod weryfikacyjny.',
    code_mismatch: 'Nieprawidłowy kod weryfikacyjny.',
    expired: 'Kod weryfikacyjny wygasł. Proszę poprosić o nowy kod weryfikacyjny.',
    exceed_max_try: 'Osiągnięto limit prób kodu weryfikacyjnego. Proszę poprosić o nowy kod weryfikacyjny.',
};
export default Object.freeze(verification_code);
